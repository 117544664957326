import React, { Suspense } from 'react'

import {LogoLoading} from "../components/Loading";
import AdminRoutes from "./AdminRoutes"
import DoctorRoutes from "./DoctorRoutes"
import AuthRoutes from "./AuthRoutes"
import { useSelector } from 'react-redux';
import { DOCTOR_ROLE, SUPER_ADMIN_ROLE } from '../constants';
// import Sidebar from '../components/Sidebar';

const BaseRoutes = () => {
  const user = useSelector(state => state.user);

  return (
      <Suspense fallback={<LogoLoading/>}>
        {
          user && user.role === SUPER_ADMIN_ROLE ?
            <AdminRoutes/>
          : user && user.role === DOCTOR_ROLE ?
            <DoctorRoutes/>
          :
          <AuthRoutes/>
        }
      </Suspense>
  )
}

export default BaseRoutes