import {FaUser, FaUsers} from "react-icons/fa";
import {AiFillHome, AiOutlineMail, AiOutlinePlusCircle} from "react-icons/ai";
import {BiCog} from "react-icons/bi";
import { HiAcademicCap } from "react-icons/hi";
import { BsViewStacked } from "react-icons/bs";
import { FormattedMessage } from "react-intl";

export const normalLinks = [
    {
        title: <FormattedMessage id="dshbrd" defaultMessage={"Dashboard"} />,
        icon: AiFillHome,
        to: "/"
    },
    {
        title: <FormattedMessage id="usrs" defaultMessage={"Users"} />,
        icon: FaUsers,
        to: "/users",
        user_noti: true
    },
    {
        title: <FormattedMessage id="c.frms" defaultMessage={"Contact Forms"} />,
        icon: AiOutlineMail,
        to: "/contact-forms"
    },
    {
        title: <FormattedMessage id="crses" defaultMessage={"Courses"} />,
        icon: HiAcademicCap,
        links: [
            {
                title: <FormattedMessage id="u.crs" defaultMessage={"Upload Course"} />,
                icon: AiOutlinePlusCircle,
                to: "/upload-course"
            },
            {
                title: <FormattedMessage id="v.crses" defaultMessage={"View Courses"} />,
                icon: BsViewStacked,
                to: "/courses"
            },
        ]
        
    },
    {
        title: <FormattedMessage id="support" defaultMessage={"Support"}/>,
        icon: AiOutlineMail,
        to: "/support",
        support_noti: true
    },
    {
        title: <FormattedMessage id="m.p" defaultMessage={"My Profile"}/>,
        icon: FaUser,
        to: "/profile"
    },
    // {
    //     title: "Settings",
    //     icon: BiCog,
    //     links: [
    //         {
    //             title: "My Profile",
    //             icon: BiCog,
    //             to: "/profiles"
    //         },
    //     ]
        
    // },
]