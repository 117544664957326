import {FaUser, FaFileInvoiceDollar} from "react-icons/fa";
import {AiFillHome, AiOutlineDollar, AiOutlineFileSearch, AiOutlineMail, AiOutlinePlusCircle} from "react-icons/ai";
import {BiCog} from "react-icons/bi";
import {GiHealthNormal} from "react-icons/gi";
import {BsViewStacked} from "react-icons/bs";
import { HiAcademicCap } from "react-icons/hi";
import { FormattedMessage } from "react-intl";

export const doctorLinks = [
    {
        title: <FormattedMessage id="dshbrd" defaultMessage={"Dashboard"} />,
        icon: AiFillHome,
        to: "/"
    },
    {
        title: <FormattedMessage id="m.p" defaultMessage={"My Profile"} />,
        icon: FaUser,
        to: "/profile"
    },
    {
        title: <FormattedMessage id="v.crses" defaultMessage={"View Courses"} />,
        icon: HiAcademicCap,
        to: "/courses"
    },
    {
        title: <FormattedMessage id="inv" defaultMessage={"Invoices"} />,
        icon: FaFileInvoiceDollar,
        links: [
            {
                title: <FormattedMessage id="a.bil.m" defaultMessage={"Add Billing Methods"} />,
                icon: AiOutlinePlusCircle,
                to: "/billing/methods/add"
            },
            {
                title: <FormattedMessage id="bil.m" defaultMessage={"Billing Methods"} />,
                icon: AiOutlineDollar,
                to: "/billing/methods"
            },
            {
                title: <FormattedMessage id="inv" defaultMessage={"Invoices"} />,
                icon: AiOutlineFileSearch,
                to: "/billing"
            },
        ]
        
    },
    {
        title: <FormattedMessage id="clncs" defaultMessage={"Clinics"}/>,
        icon: GiHealthNormal,
        links: [
            {
                title: <FormattedMessage id="a.clncs" defaultMessage={"Add Clinics"}/>,
                icon: AiOutlinePlusCircle,
                to: "/clinics/add"
            },
            {
                title: <FormattedMessage id="v.clncs" defaultMessage={"View Clinics"}/>,
                icon: BsViewStacked,
                to: "/clinics"
            },
        ]
        
    },
    {
        title: <FormattedMessage id="cses" defaultMessage={"Cases"}/>,
        icon: GiHealthNormal,
        user_noti: true,
        links: [
            {
                title: <FormattedMessage id="a.cse" defaultMessage={"Add Cases"}/>,
                icon: AiOutlinePlusCircle,
                to: "/cases/add"
            },
            {
                title: <FormattedMessage id="v.cses" defaultMessage={"View Cases"}/>,
                icon: BsViewStacked,
                to: "/cases"
            },
        ]
        
    },
    {
        title: <FormattedMessage id="support" defaultMessage={"Support"}/>,
        icon: AiOutlineMail,
        to: "/support",
        support_noti: true

    },
]