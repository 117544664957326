import React, { useState } from "react";
//redux imports
import { Provider, useSelector } from "react-redux";
import store from "./redux/store";
//redux imports

// notifications alerts
import 'react-notifications-component/dist/theme.css'
import ReactNotification from 'react-notifications-component'
import 'animate.css/animate.min.css';

// notifications alerts

//multilingual imports
import { IntlProvider } from "react-intl";
import en from "./languages/en.json";
import es from "./languages/es.json";
import fr from "./languages/fr.json";
//multilingual imports

//router
import { BrowserRouter } from "react-router-dom";
import BaseRoutes from "./routes";
//router

//global popup
import BasePopup, { BasePopupContext } from './components/BasePopup';
import { ENGLISH_LANGUAGE, FRENCH_LANGUAGE, SPANISH_LANGUAGE } from "./constants";
//global popup

const AppContainer = () => {
  const lang = useSelector(state => state.lang)
  // state for popup
  const initialPopupState = {
    component: null,
    visible: false,
    size: "small",
    heading: "",
    contained: false,
    hideDefaultClose: false
  }
  const [popupState, setPopupState] = useState(initialPopupState);

  const openPopup = (params) => setPopupState(old => ({ ...old, ...params }));

  const global_popup_context_data = {
    popupState,
    setPopup: openPopup
  }
  // state for popup

  return (
    <> 
      <IntlProvider messages={lang.value === ENGLISH_LANGUAGE.value ? en : lang.value === SPANISH_LANGUAGE.value ? es : lang.value === FRENCH_LANGUAGE.value ? fr : en} locale="en" defaultLocale="en">
          <BasePopupContext.Provider value={global_popup_context_data}>
          <BrowserRouter>
              <ReactNotification/>
              <BaseRoutes />
              <BasePopup />
          </BrowserRouter>
          </BasePopupContext.Provider>
      </IntlProvider>
    </>
  );
}

const App = () => {

 
  return (
    <> 
        <Provider store={store}>
          <AppContainer/>
        </Provider>
    </>
  );
}

export default App;
