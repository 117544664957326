import { createSlice } from "@reduxjs/toolkit";
import {getAuthUser} from "../../utils";

const initialState = {
    loading: false,
    data: null,
    reload: 0
}

const uiSlice = createSlice({
    initialState,
    name: "sidebar_notifications",
    reducers: {
        set_loading: (state, action) => {
            state.loading = action.payload;
            return state
        },
        set_notifications: (state, action) => {
            state.data = action.payload;
            return state
        },
        set_notifications_reload: (state, action) => {
            state.reload = state.reload + 1;
            return state
        },
    }
})

export default uiSlice.reducer;

export const {
    set_notifications,
    set_loading,
    set_notifications_reload
} = uiSlice.actions;
