import React from 'react'
import { useSelector } from 'react-redux';
import { useLocation, Link } from 'react-router-dom';

const LinkContainer = ({Icon, title, to,item}) => {
  const { pathname } = useLocation();
  const {data} = useSelector(state => state.sidebar_notifications)
  return (
    <>
        <Link to={to} className="flex group items-center space-x-2">
          <div className={`w-[40%] transition-all group-hover:bg-primary/20 py-4 rounded-tr-full rounded-br-full flex justify-end pr-4 ${pathname === to ? "bg-primary/20" : ""}`}>
            <Icon className={`text-2xl group-hover:text-primary ${pathname === to ? "text-primary" : ""}`}/>
          </div>
          <span className={`font-medium text-gray-700 group-hover:text-primary text-xs ${pathname === to ? "text-primary" : ""}`}>
            {title}
            
          </span>
            {
              item.user_noti ?
                data && data.users > 0 ?
                  <span className='text-xs flex-shrink-0 ml-1 bg-primary rounded-full h-6 w-6 flex items-center justify-center text-white'>
                    {data.users}
                  </span>
                : null
              :item.support_noti ?
                data && data.tickets > 0 ?
                  <span className='text-xs flex-shrink-0 ml-1 bg-primary rounded-full h-6 w-6 flex items-center justify-center text-white'>
                    {data.tickets}
                  </span>
                : null
              : null
            }
      </Link>
    </>
  )
}

export default LinkContainer