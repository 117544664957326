import React, { Suspense } from 'react';
import { Route, Routes, Navigate } from 'react-router';

import { LogoLoading } from "../../components/Loading";
import Sidebar from '../../components/Sidebar';

// page imports
const Dashboard = React.lazy(() => import('../../pages/Doctor/Dashboard'));
const Profile = React.lazy(() => import('../../pages/Account'));
const AddBillingMethod = React.lazy(() => import('../../pages/Doctor/AddBillingMethod'));
const BillingMethodListing = React.lazy(() => import('../../pages/Doctor/BillingMethodListing'));
const BillingListing = React.lazy(() => import('../../pages/Doctor/BillingListing'));
const BillingMethodDetail = React.lazy(() => import('../../pages/Doctor/BillingMethodDetail'));
const AddClinic = React.lazy(() => import('../../pages/Doctor/AddClinic'));
const ClinicListing = React.lazy(() => import('../../pages/Doctor/ClinicListing'));
const ClinicDetail = React.lazy(() => import('../../pages/Doctor/ClinicDetail'));
const AddCase = React.lazy(() => import('../../pages/Doctor/AddCase'));
const CaseListing = React.lazy(() => import('../../pages/Doctor/CaseListing'));
const CaseDetail = React.lazy(() => import('../../pages/Doctor/CaseDetail'));
const Support = React.lazy(() => import('../../pages/SupportListing/Listing'));
const SupportDetail = React.lazy(() => import('../../pages/SupportDetail'));
const CoursesListing = React.lazy(() => import('../../pages/Admin/CoursesListing'));

// page imports

function AdminRoutes(props) {
  return (
    <>
      <Sidebar />
        <Suspense fallback={<LogoLoading />}>
          <Routes>
            <Route path="/" element={<Dashboard />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/billing" element={<BillingListing />} />
            <Route path="/billing/methods" element={<BillingMethodListing />} />
            <Route path="/billing/methods/add" element={<AddBillingMethod />} />
            <Route path="/clinics/add" element={<AddClinic />} />
            <Route path="/clinics" element={<ClinicListing />} />
            <Route path="/clinics/:id" element={<ClinicDetail />} />
            <Route path="/billing-methods/:id" element={<BillingMethodDetail />} />
            <Route path="/cases/add" element={<AddCase />} />
            <Route path="/cases" element={<CaseListing />} />
            <Route path="/cases/:id" element={<CaseDetail />} />
            <Route path="/support" element={<Support />} />
            <Route path="/support/:id" element={<SupportDetail />} />
            <Route path="/courses" element={<CoursesListing />} />

            <Route path='*' element={<Navigate to="/" />} />
          </Routes>
        </Suspense>
    </>
  )
}

export default AdminRoutes
