import React, { Suspense, useEffect } from 'react';
import { Route, Routes, Navigate } from "react-router";
import CustomerHeader from '../../components/CustomerHeader';
import CustomerFooter from '../../components/CustomerFooter';

import { LogoLoading } from "../../components/Loading";

// const Login = React.lazy(() => import("../../pages/Login"));
const Home = React.lazy(() => import('../../pages/Customer/Home'));
const About = React.lazy(() => import('../../pages/Customer/About'));
const Results = React.lazy(() => import('../../pages/Customer/Results'));
const Youths = React.lazy(() => import('../../pages/Customer/Youths'));
const Login = React.lazy(() => import('../../pages/Customer/Login'));
const ForgotPassword = React.lazy(() => import('../../pages/Customer/ForgotPassword'));
const SetPassword = React.lazy(() => import("../../pages/Customer/SetPassword"));

function AuthRoute() {
    useEffect(() => {
    }, [])
    return (
        <Suspense fallback={<LogoLoading />}>
           <div className="flex flex-col items-start h-full">
            {/* <CustomerHeader/> */}
            <div className="flex-grow w-full">
                <Routes>
                    {/* <Route path='/' element={<Home />} /> */}
                    <Route path='/login' element={<Login />} />
                    <Route path='/forgot-password' element={<ForgotPassword />} />
                    <Route path="/set-password/:token" element={<SetPassword />} />
{/*                     
                    <Route path='/about' element={<About />} />
                    <Route path='/results' element={<Results />} />
                    <Route path='/youths' element={<Youths />} /> */}
                    <Route path='*' element={<Navigate to="/login" />} />
                </Routes>
            </div>
            {/* <CustomerFooter/> */}
           </div>
        </Suspense>
    )
}

export default AuthRoute
