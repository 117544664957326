import { createSlice } from "@reduxjs/toolkit";
import { ENGLISH_LANGUAGE } from "../../constants";

const initialState = ENGLISH_LANGUAGE

const langSlice = createSlice({
    initialState,
    name: 'lang',
    reducers: {
        set_lang: (state, action) => {
            return action.payload
        }
    }
})

export default langSlice.reducer;

export const {
    set_lang
} = langSlice.actions