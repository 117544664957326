import { FormattedMessage } from "react-intl";

// export const BASE_URL = "http://192.168.0.102:8000/";
export const BASE_URL = "https://appbackend.idealignlabs.com/";
export const DATE_FORMAT_SERVER = "yyyy-MM-dd";
export const DATE_FORMAT_DISPLAY = "dd/MM/yyyy";
export const ACCESS_TOKEN = "idealign_access";
export const PROMOTION_TOKEN = "pro_id_app";

export const POPUP_SMALL = "small";
export const POPUP_MEDIUM = "medium";
export const POPUP_LARGE = "large";

export const CUSTOM_TYPE = "custom-link"
export const SAVE_CONTACT = "save-contact";



export const SAVE_CONTACT_INITIAL_DATA = {
    name: "Save Contact",
    value: "",
    type: SAVE_CONTACT,
    icon_url: "https://s3.eu-central-1.amazonaws.com/pro-id/media/icons/telefon.png",
    platform:"save-contact",
    code:"save-contact",
    output_field: "link"
}

export const FIELD_TEXT = "text";
export const FIELD_NUMBER = "number";
export const FIELD_LINK = "link";
export const FIELD_EMAIL = "email";
export const NUMBER_LINK = "number_link";


export const LISTING_LIMIT = 10;

export const initialPopupState = {
    component: null,
    visible: false,
    size: "small",
    heading: "",
    contained: false,
    hideDefaultClose: false,
    second_component: false
}


//social and card type

export const FACEBOOK = {
    code: "facebook",
    url: "https://www.facebook.com/"
};
export const INSTAGRAM = {
    code: "instagram",
    url: "https://www.instagram.com/"
};
export const SNAPCHAT = {
    code: "snapchat",
    url: "https://www.snapchat.com/add/"
};
export const TWITTER = {
    code: "twitter",
    url: "https://www.twitter.com/"
};

export const SOCIAL_URLS = [
    FACEBOOK,
    INSTAGRAM,
    SNAPCHAT,
    TWITTER
] 

export const MAX_CHARS_BIO = 100;
export const SUPPORTED_IMAGES = ["image/png","image/jpeg"]

export const SUPER_ADMIN_ROLE = "super-admin"
export const DOCTOR_ROLE = "doctor"

export const PAGINATION_OPTIONS = {
    rowsPerPageText: <FormattedMessage id="rows.page" defaultMessage="Rows per page" />,
    rangeSeparatorText: '-',
    selectAllRowsItem: true,
    selectAllRowsItemText: <FormattedMessage id="select.all" defaultMessage={"Select All"}/>,
};

export const SPAIN_PROVINCES= [
    {
        value:"Álava",
        label:"Álava",
    },
    {
        value:"Albacete",
        label:"Albacete",
    },
    {
        value:"Alicante",
        label:"Alicante",
    },
    {
        value:"Almería",
        label:"Almería",
    },
    {
        value:"Asturias",
        label:"Asturias",
    },
    {
        value:"Ávila",
        label:"Ávila",
    },
    {
        value:"Badajoz",
        label:"Badajoz",
    },
    {
        value:"Islas Baleares",
        label:"Islas Baleares",
    },
    {
        value:"Barcelona",
        label:"Barcelona",
    },
    {
        value:"Burgos",
        label:"Burgos",
    },
    {
        value:"Cáceres",
        label:"Cáceres",
    },
    {
        value:"Cádiz",
        label:"Cádiz",
    },
    {
        value:"Cantabria",
        label:"Cantabria",
    },
    {
        value:"Castellón",
        label:"Castellón",
    },
    {
        value:"Ciudad Real",
        label:"Ciudad Real",
    },
    {
        value:"Córdoba",
        label:"Córdoba",
    },
    {
        value:"La Coruña",
        label:"La Coruña",
    },
    {
        value:"Cuenca",
        label:"Cuenca",
    },
    {
        value:"Gerona",
        label:"Gerona",
    },
    {
        value:"Granada",
        label:"Granada",
    },
    {
        value:"Guadalajara",
        label:"Guadalajara",
    },
    {
        value:"Guipúzcoa",
        label:"Guipúzcoa",
    },
    {
        value:"Huelva",
        label:"Huelva",
    },
    {
        value:"Huesca",
        label:"Huesca",
    },
    {
        value:"Jaén",
        label:"Jaén",
    },
    {
        value:"León",
        label:"León",
    },
    {
        value:"Lérida",
        label:"Lérida",
    },
    {
        value:"La Rioja",
        label:"La Rioja",
    },
    {
        value:"Lugo",
        label:"Lugo",
    },
    {
        value:"Madrid",
        label:"Madrid",
    },
    {
        value:"Málaga",
        label:"Málaga",
    },
    {
        value:"Murcia",
        label:"Murcia",
    },
    {
        value:"Navarra",
        label:"Navarra",
    },
    {
        value:"Orense",
        label:"Orense",
    },
    {
        value:"Palencia",
        label:"Palencia",
    },
    {
        value:"Las Palmas",
        label:"Las Palmas",
    },
    {
        value:"Pontevedra",
        label:"Pontevedra",
    },
    {
        value:"Salamanca",
        label:"Salamanca",
    },
    {
        value:"Santa Cruz de Tenerife",
        label:"Santa Cruz de Tenerife",
    },
    {
        value:"Segovia",
        label:"Segovia",
    },
    {
        value:"Sevilla",
        label:"Sevilla",
    },
    {
        value:"Soria",
        label:"Soria",
    },
    {
        value:"Tarragona",
        label:"Tarragona",
    },
    {
        value:"Teruel",
        label:"Teruel",
    },
    {
        value:"Toledo",
        label:"Toledo",
    },
    {
        value:"Valencia",
        label:"Valencia",
    },
    {
        value:"Valladolid",
        label:"Valladolid",
    },
    {
        value:"Vizcaya",
        label:"Vizcaya",
    },
    {
        value:"Zamora",
        label:"Zamora",
    },
    {
        value:"Zaragoza",
        label:"Zaragoza",
    },
    {
        value:"Ceuta",
        label:"Ceuta",
    },
    {
        value:"Ceuta",
        label:"Ceuta",
    },
    {
        value:"Melilla",
        label:"Melilla",
    },

]

export const SPAIN_COMMUNITIES = [
    {
        value: "Andalucía",
        label: "Andalucía",
    },
    {
        value: "Aragón",
        label: "Aragón",
    },
    {
        value: "Principado de Asturias",
        label: "Principado de Asturias",
    },
    {
        value: "Islas Baleares",
        label: "Islas Baleares",
    },
    {
        value: "Canarias",
        label: "Canarias",
    },
    {
        value: "Cantabria",
        label: "Cantabria",
    },
    {
        value: "Castilla y León",
        label: "Castilla y León",
    },
    {
        value: "Castilla-La Mancha",
        label: "Castilla-La Mancha",
    },
    {
        value: "Cataluña",
        label: "Cataluña",
    },
    {
        value: "Comunidad de Madrid",
        label: "Comunidad de Madrid",
    },
    {
        value: "Comunidad Valenciana",
        label: "Comunidad Valenciana",
    },
    {
        value: "Región de Murcia",
        label: "Región de Murcia",
    },
    {
        value: "Comunidad Foral de Navarra",
        label: "Comunidad Foral de Navarra",
    },
    {
        value: "País Vasco",
        label: "País Vasco",
    },
    {
        value: "La Rioja",
        label: "La Rioja",
    },
    {
        value: "Ciudad Autónoma de Ceuta",
        label: "Ciudad Autónoma de Ceuta",
    },
    {
        value: "Ciudad Autónoma de Melilla",
        label: "Ciudad Autónoma de Melilla",
    },
]

export const WIRE_TRANSFER = {
    value: "wire-transfer",
    label: "Wire Transfer"
}
export const DEBIT_CREDIT_TRANSFER = {
    value: "debit-credit",
    label: "Debit/Credit Card"
}

export const PAYMENT_TYPE = [
    WIRE_TRANSFER,
    DEBIT_CREDIT_TRANSFER
]

export const DAYS_OF_WEEK = [
    {
        value: "monday",
        label: <FormattedMessage id="mday" defaultMessage="Monday"/>,
    },
    {
        value: "tuesday",
        label: <FormattedMessage id="tday" defaultMessage="Tuesday"/>,
    },
    {
        value: "wednesday",
        label: <FormattedMessage id="wday" defaultMessage="Wednesday"/>,
    },
    {
        value: "thursday",
        label: <FormattedMessage id="thrday" defaultMessage="Thursday"/>,
    },
    {
        value: "friday",
        label: <FormattedMessage id="frday" defaultMessage="Friday"/>,
    },
    {
        value: "saturday",
        label: <FormattedMessage id="saday" defaultMessage="Satuday"/>,
    },
    {
        value: "sunday",
        label: <FormattedMessage id="sday" defaultMessage="Sunday"/>,
    },
]

export const ACTIVE_CASES = {
    value: "active-cases",
    label: <FormattedMessage id="a.cses" defaultMessage="Active Cases"/>
}
export const PAST_CASES = {
    value: "past-cases",
    label: <FormattedMessage id="p.cses" defaultMessage="Past Cases"/>
}

export const APPROVED = {
    value: "approved",
    label: <FormattedMessage id="aprvd" defaultMessage="Approved"/>
}
export const NOT_APPROVED = {
    value: "not-approved",
    label: <FormattedMessage id="n.aprvd" defaultMessage="Not Approved"/>
}
export const PENDING = {
    value: "pending",
    label: <FormattedMessage id="pending" defaultMessage="Pending"/>
}
export const ACTIVE = {
    value: "active",
    label: <FormattedMessage id="acitve" defaultMessage="Active"/>
}
export const CLOSED = {
    value: "closed",
    label: <FormattedMessage id="clsed" defaultMessage="Closed"/>
}

export const STATUS_ARR = [
    APPROVED,
    NOT_APPROVED,
    PENDING,
    CLOSED
]

export const MALE = {
    value: "male",
    label: <FormattedMessage id="mle" defaultMessage="Male"/>
}
export const FEMALE = {
    value: "female",
    label: <FormattedMessage id="fmle" defaultMessage="Female"/>
}

export const GENDER = [
    MALE,
    FEMALE,
]

export const TYPE_OF_TREATMENT = [
    {
        value: "without-worries",
        label: <FormattedMessage id="wi.wr" defaultMessage="Without Worries"/>
    },
    {
        value: "to-your-measure",
        label: <FormattedMessage id="t.y.m" defaultMessage="To your measure"/>
    },
    {
        value: "pre-prosthetic-treatment",
        label: <FormattedMessage id="pr.p.t" defaultMessage="Pre-Prosthetic Treatment"/> 
    },
    {
        value: "retainers",
        label: <FormattedMessage id="retaners" defaultMessage="Retainers"/> 
    },
]
export const ARCH_TO_TREAT = [
    {
        value: "botch-arches",
        label: <FormattedMessage id="b.arche" defaultMessage="Both arches"/>  
    },
    {
        value: "upper-arch",
        label: <FormattedMessage id="u.arche" defaultMessage="Upper Arch"/> 
    },
    {
        value: "lower-arch",
        label: <FormattedMessage id="l.arche" defaultMessage="Lower Arch"/>
    },
]
export const ALIGNER_TRIM = [
    {
        value: "trim-1-3mm",
        label: <FormattedMessage id="t.1.3" defaultMessage="Trim leaving 1-3mm of gum"/> 
    },
    {
        value: "trim-neck",
        label: <FormattedMessage id="t.n.l" defaultMessage="Trim At Neck Level"/> 
    },
]
export const RETAINERS_TYPE = [
    {
        value: "fixed",
        label: <FormattedMessage id="fxed" defaultMessage="Fixed"/> 
    },
    {
        value: "removable",
        label: <FormattedMessage id="rmvb" defaultMessage="Removable"/> 
    },
]

export const ENGLISH_LANGUAGE = {
    value: "en",
    label: "English",
    ltr: true,
}
export const SPANISH_LANGUAGE = {
    value: "es",
    label: "Espanol",
    ltr: true,
}
export const FRENCH_LANGUAGE = {
    value: "fr",
    label: "French",
    ltr: true,
}

export const LANG_ARR = [
    ENGLISH_LANGUAGE,
    SPANISH_LANGUAGE,
    FRENCH_LANGUAGE
]