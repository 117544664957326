import React, { Suspense } from 'react';
import { Route, Routes, Navigate } from 'react-router';

import { LogoLoading } from "../../components/Loading";
import Sidebar from '../../components/Sidebar';

// page imports
const Dashboard = React.lazy(() => import('../../pages/Admin/Dashboard'));
const Users = React.lazy(() => import('../../pages/Admin/Users'));
const UserDetail = React.lazy(() => import('../../pages/Admin/UserDetail'));
const UploadBilling = React.lazy(() => import('../../pages/Admin/UploadBilling'));
const BillingListing = React.lazy(() => import('../../pages/Doctor/BillingListing'));
const CaseDetail = React.lazy(() => import('../../pages/Admin/CaseDetail'));
const ContactListing = React.lazy(() => import('../../pages/Admin/ContactListing'));
const Support = React.lazy(() => import('../../pages/SupportListing/Listing'));
const SupportDetail = React.lazy(() => import('../../pages/SupportDetail'));
const UploadCourse = React.lazy(() => import('../../pages/Admin/UploadCourses'));
const CoursesListing = React.lazy(() => import('../../pages/Admin/CoursesListing'));
const ClinicDetail = React.lazy(() => import('../../pages/Doctor/ClinicDetail'));
const BillingMethodDetail = React.lazy(() => import('../../pages/Doctor/BillingMethodDetail'));

const Profile = React.lazy(() => import('../../pages/Account'));
// page imports


function AdminRoutes(props) {
  return (
    <>
      <Sidebar />
        <Suspense fallback={<LogoLoading />}>
          <Routes>
            <Route path="/" element={<Dashboard />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/users" element={<Users />} />
            {/* this all is in user detail */}
            <Route path="/users/:id" element={<UserDetail />} />
            <Route path="/upload-billing/:id" element={<UploadBilling />} />
            <Route path="/billing/:id" element={<BillingListing />} />
            <Route path="/users/:id/cases/:case_id" element={<CaseDetail />} />
            <Route path="/contact-forms" element={<ContactListing />} />
            <Route path="/support" element={<Support />} />
            <Route path="/support/:id" element={<SupportDetail />} />
            <Route path="/upload-course" element={<UploadCourse />} />
            <Route path="/courses" element={<CoursesListing />} />
            <Route path="/users/:user_id/clinics/:id" element={<ClinicDetail />} />
            <Route path="/users/:user_id/billing-methods/:id" element={<BillingMethodDetail />} />

            {/* this all is in user detail */}
            <Route path='*' element={<Navigate to="/" />} />
          </Routes>
        </Suspense>
    </>
  )
}

export default AdminRoutes
