import React from 'react'
import Link from './Link';
import NestedLink from './NestedLink';
import { normalLinks } from '../../routes/AdminRoutes/normalLinks';
import { doctorLinks } from '../../routes/DoctorRoutes/normalLinks';
import { logout } from '../../redux/slices/userSlice';
import { IoLogOutOutline } from "react-icons/io5";
import { useDispatch, useSelector } from 'react-redux';
import {FormattedMessage, useIntl} from "react-intl";
import { removeToken, success_alert } from '../../utils';
import { DOCTOR_ROLE, SUPER_ADMIN_ROLE } from '../../constants';

const Sidebar = () => {
  const user = useSelector(state => state.user)
  const sidebar_links = user.role === SUPER_ADMIN_ROLE ? normalLinks : user.role === DOCTOR_ROLE ? doctorLinks : doctorLinks
  const intl = useIntl()
  const sidebar = useSelector(state => state.ui.sidebar);
  const dispatch = useDispatch()
  const handleLogout = () => {
    removeToken();
    dispatch(logout());
    success_alert(intl.formatMessage({id:"logout.s", defaultMessage:"You were logged out sucessfully!"}))
}
  return (
    <>
      <div className={`z-[101] transition-transform bg-white flex flex-col overflow-y-auto fixed top-0 bottom-0 w-[250px] pt-6 transform lg:translate-x-0 ${sidebar ? "translate-x-0" : "translate-x-[-100%]"}`}>
        <div className="flex-shrink-0">
          <img src="/images/logo.png" alt="" className='mb-12 w-[140px] mx-auto' />
        </div>

        <div className="flex-grow space-y-2">
          {
            sidebar_links.map((item, index) => (
              item.links ?
                <NestedLink key={index} title={item.title} Icon={item.icon} to={item.to} links={item.links} item={item} />
                :
                <Link key={index} title={item.title} Icon={item.icon} to={item.to}  item={item}/>

            ))
          }

        </div>

        <div className="flex-shrink-0 pb-12">
          <button onClick={handleLogout} className="w-full flex group items-center space-x-2">
            <div className={`w-[30%] transition-all group-hover:bg-primary/20  py-5 rounded-tr-full rounded-br-full flex justify-end pr-4`}>
              <IoLogOutOutline className={`transform scale-x-[-1] text-4xl group-hover:text-primary `} />
            </div>
            <span className={`group-hover:text-primary opacity-1 font-medium text-gray-700 group-hover:text-primary text-sm `}>
              <FormattedMessage id="logout" defaultMessage={"Logout"}/>
            </span>
          </button>

          <div className="flex justify-end mt-4">
            <p className="w-[89%] text-sm text-left text-gray-400">
              V 0.0.1
            </p>
          </div>

        </div>

      </div>
      {
        sidebar ?
          <div className="bg-black bg-opacity-25 fixed top-0 left-0 right-0 bottom-0 z-[100]"></div>
          : null
      }
    </>
  )
}

export default Sidebar