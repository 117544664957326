import { configureStore } from "@reduxjs/toolkit";

//reducers below
import userReducer from "./slices/userSlice";
import uiReducer from "./slices/uiSlice";
import langReducer from "./slices/langSlice";
import doctorSlice from "./slices/doctorSlice";
import sidebarNotificationSlice from "./slices/sidebarNotifications";
//reducers above

export default configureStore({
    reducer:{
        user: userReducer,
        ui: uiReducer,
        lang: langReducer,
        doctor_data: doctorSlice,
        sidebar_notifications: sidebarNotificationSlice
    } 
})
