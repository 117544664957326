import { createSlice } from "@reduxjs/toolkit";
import {getAuthUser} from "../../utils";

const initialState = false;

const doctorSlice = createSlice({
    initialState,
    name: "doctor_data",
    reducers: {
        set_data: (state, action) => action.payload,
    }
})

export default doctorSlice.reducer;

export const {
    set_data
} = doctorSlice.actions;
